const SHOW_ERROR_TYPE = {
  UNDEFINED: -1,
  MODAL: 0,
  TOAST: 1,
  INPUT: 2,
  PRODUCT: 3,
  EXPIRED_CART: 4,
  ECI_CARD: 5,
  EXPIRED_TIME_SLOT: 6,
  COUPON: 7
};

const translateMessageError = code => {
  return 'checkout.errors.' + code;
};

// eslint-disable-next-line complexity
const cartFormatError = ERROR => {
  const error = {
    msg: translateMessageError(ERROR.code),
    show: {
      type: SHOW_ERROR_TYPE.UNDEFINED,
      product: false
    },
    param: null
  };

  switch (ERROR.code) {
    case 'TS100':
      error.show.type = SHOW_ERROR_TYPE.EXPIRED_TIME_SLOT;
      break;
    case 'CRT002':
    case 'CRT009':
    case 'CRT187':
    case 'CRT1368':
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
    case 'EC001':
    case 'EC002':
    case 'EC003':
    case 'EC004':
    case 'EC005':
    case 'EC006':
    case 'EC007':
      error.msg = ERROR.description;
      error.show.type = SHOW_ERROR_TYPE.ECI_CARD;
      break;
    case 'CRT138':
    case 'CRT101':
    case 'CRT1369':
    case 'CRT182':
    case 'SG046':
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
    case 'CRT087':
    case 'CRT073':
    case 'CRT074':
    case 'COUPON_SERVICE_0005':
      error.show.type = SHOW_ERROR_TYPE.COUPON;
      break;
    case 'errorAddingToOrder':
      error.msg = ERROR.description;
      error.show.type = SHOW_ERROR_TYPE.MODAL;
      break;
    case 'CRT019':
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
    case 'SG002':
    case 'SG003':
    case 'SG006':
    case 'SG007':
    case 'SG009':
    case 'SG010':
    case 'SG011':
    case 'SG012':
    case 'SG013':
    case 'SG014':
    case 'SG014_city':
    case 'SG014_phone_number':
    case 'SG014_building':
    case 'SG014_door':
    case 'SG015':
    case 'SG016':
    case 'SG017':
    case 'SG018':
    case 'SG019':
    case 'SG020':
    case 'SG021':
    case 'SG022':
    case 'SG023':
    case 'SG024':
    case 'SG025':
    case 'SG026':
    case 'SG027':
    case 'SG028':
    case 'SG029':
    case 'SG030':
    case 'SG036':
    case 'SG037':
    case 'SG038':
    case 'SG041':
    case 'SG043':
    case 'SG044':
    case 'TS006':
    case 'C409':
      error.show.type = SHOW_ERROR_TYPE.MODAL;
      break;
    case 'SG047':
    case 'SG032':
      const formatParam = ERROR.params ? ERROR.params[0] : null;
      error.msg = 'checkout.errors.' + ERROR.code;
      error.param = { price: formatParam };
      error.show.type = SHOW_ERROR_TYPE.MODAL;
      break;
    case 'OE-VOU-0005':
      error.msg = 'checkout.errors.OE_VOU_0005';
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
    case 'CRT159':
    case 'INS002':
    case 'CRT221':
    case 'CRT232':
      error.msg = 'checkout.errors.' + ERROR.code;
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
    case 'INSUFFICIENT_MARKETPLACE_STOCK':
      error.msg = ERROR.description;  
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
    default:
      error.msg = 'Lo sentimos, ha ocurrido un problema. Vuelve a intentarlo más tarde.';
      error.show.type = SHOW_ERROR_TYPE.TOAST;
      break;
  }

  return error;
};

export default {
  cartFormatError: cartFormatError,
  SHOW_ERROR_TYPE: SHOW_ERROR_TYPE
};
